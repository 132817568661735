<template>
  <div class="systemLog">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      :form-item-arr="searchFormItemArr"
      :reset="false"
    />
    <!-- <el-form :inline="true" :model="formInline" class="search">
      <el-form-item label-width="72px" label="操作类型">
        <el-select size="small" filterable v-model="formInline.operType" placeholder="请选择">
          <el-option label="全部" value></el-option>
          <el-option
            v-for="item in operationType"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label-width="72px" label="业务类型">
        <el-select size="small" filterable v-model="formInline.busiType" placeholder="请选择">
          <el-option label="全部" value></el-option>
          <el-option
            v-for="item in businessType"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="getdata(false)">搜索</el-button>
      </el-form-item>
    </el-form> -->
    <!--列表-->
    <div class="page-container-table">
      <Table :item-data="itemData" :list-data="listData" :loading="loading">
        <template #operType="{ row }">
          <span>{{ row.operType === 0 ? '登录' : row.operType === 1 ? '新增' : row.operType === 2 ? '修改' : '删除' }}</span>
        </template>
        <template #busiType="{ row }">
          <span>{{ row.busiType === 1 ? '登录模块' : row.busiType === 2 ? '产品模块' : row.busiType === 3 ? '额度模块' : '融资模块' }}</span>
        </template>
      </Table>
      <!-- <el-table
        size="small"
        :data="listData"
        highlight-current-row
        v-loading="loading"
        border
        element-loading-text="拼命加载中"
        style="width: 100%;"
      >
        <el-table-column align="center" prop="operType" label="操作类型">
          <template slot-scope="scope">
            <span v-if="scope.row.operType==0">登录</span>
            <span v-else-if="scope.row.operType==1">新增</span>
            <span v-else-if="scope.row.operType==2">修改</span>
            <span v-else-if="scope.row.operType==3">删除</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="busiType" label="业务类型">
          <template slot-scope="scope">
            <span v-if="scope.row.busiType==1">登录模块</span>
            <span v-else-if="scope.row.busiType==2">产品模块</span>
            <span v-else-if="scope.row.busiType==3">额度模块</span>
            <span v-else-if="scope.row.busiType==4">融资模块</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="operEvent" label="操作事件"></el-table-column>
        <el-table-column align="center" prop="operUserName" label="操作人"></el-table-column>
        <el-table-column align="center" prop="createTime" label="操作时间">
        </el-table-column>
        <el-table-column align="center" prop="operUserCode" label="登录账号"></el-table-column>
      </el-table> -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
  </div>
</template>

<script>
import { timestampToTime } from '../../utils/util'
import { getSyslog } from '../../api/systemManagement'
import { operationType, businessType } from '../../systemConfiguration/index'
import Pagination from '../../components/Pagination2'
import Table from '@/components/Table'
import FormSearch from '../../components/FormSearch'
export default {
  components: { Pagination, Table, FormSearch },
  data() {
    return {
      operationType,
      businessType,
      listData: [],
      total: 0,
      loading: false,
      formInline: {
        pageNum: 1,
        pageSize: 10
      },
      itemData: [
        { label: '操作类型', slotName: 'operType' },
        { label: '业务类型', slotName: 'busiType' },
        { label: '操作事件', prop: 'operEvent' },
        { label: '操作人', prop: 'operUserName' },
        { label: '操作时间', prop: 'createTime' },
        { label: '登录账号', prop: 'operUserCode' }
      ],
      searchFormItemArr: [
        { type: 'select', label: '操作类型', value: 'operType', pLabel: 'name', pValue: 'id', child: operationType },
        { type: 'select', label: '业务类型', value: 'busiType', pLabel: 'name', pValue: 'id', child: businessType }
      ]
    }
  },
  methods: {
    getdata(type) {
      if (!type) this.formInline.pageNum = 1
      getSyslog(this.formInline, res => {
        this.total = res.data.total
        this.listData = [...res.data.list]
        this.listData.forEach(item => {
          item.createTime = timestampToTime(item.createTime)
        })
      })
    }
  }
}
</script>

<style>
.search {
  margin-top: 20px;
}
</style>
